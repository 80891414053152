/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { useContext } from "react"
import VisibilitySensor from "react-visibility-sensor"

import { TOCContext } from "../context/tocContext"

export default function H2(props) {
  const tocContext = useContext(TOCContext)

  const visibilityChange = (isVisible) => {
    if (tocContext.headings.length > 0 && isVisible) {
      tocContext.setToC({
        selectedHeading: { depth: 2, value: props.children },
      })
    }
  }

  return (
    <VisibilitySensor
      offset={{ bottom: 100, top: 10 }}
      onChange={visibilityChange}
    >
      <Styled.h2
        id={props.children && props.children.toLowerCase().split(" ").join("-")}
      >
        {props.children}
      </Styled.h2>
    </VisibilitySensor>
  )
}
