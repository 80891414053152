/** @jsx jsx */
import { jsx } from "theme-ui"
import { MDXProvider } from "@mdx-js/react"
import Helmet from "react-helmet"

import useSiteMetadata from "../static_queries/useSiteMetadata"
import Header from "./Header"
import H1 from "./H1"
import H2 from "./H2"

export default function Layout(props) {
  const { title, description } = useSiteMetadata()
  return (
    <section
      sx={{
        display: ["block", "flex"],
        flexDirection: "column",
        minHeight: "100vh",
        ...(props.page === "info" ? { color: "lighttext" } : {}),
        backgroundColor: props.bgColor,
      }}
    >
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Helmet>
      <Header page={props.page} title={title} levels={props.levels} />
      <MDXProvider components={{ h1: H1, h2: H2 }}>
        <div
          sx={{
            flexGrow: props.page !== "info" ? [1, "none"] : [1, "none"],
            width: props.page !== "info" ? "auto" : ["auto", "70%"],
            marginLeft: props.page !== "info" ? "" : ["", "96px"],
          }}
        >
          {props.children}
        </div>
      </MDXProvider>
    </section>
  )
}
