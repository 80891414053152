/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { Link } from "gatsby"

export default function Header(props) {
  let urlParams
  let redirectParam
  if (typeof window !== `undefined`) {
    urlParams = new URLSearchParams(window.location.search)
    redirectParam = urlParams.get("redirect")
  }

  return (
    <header
      sx={{
        height: "auto",
        width: ["auto", "100%"],
      }}
    >
      <nav
        role="navigation"
        aria-label="main navigation"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: ["center", "flex-start"],
          padding: ["2rem", "1.5rem 1.25rem"],
          height: ["auto", "100%"],
          borderBottom: ["1px solid #ebebeb", "none"],
          marginLeft: ["", "96px"],
          marginRight: ["", "96px"],
        }}
      >
        <Link to="/">
          <Styled.h1 sx={{ marginBottom: 0 }}>{props.title}</Styled.h1>
          <span sx={{ color: "ranglered" }}>by Rangle.io</span>
        </Link>

        <div>
          <Styled.h1 sx={{ marginBottom: 0 }}>
            <Link
              to={
                props.page === "info"
                  ? `${redirectParam || "/"}`
                  : `/info?redirect=${
                      typeof window !== `undefined` && window.location.pathname
                    }`
              }
            >
              {props.page === "info" ? "close" : "info"}
            </Link>
          </Styled.h1>
        </div>
      </nav>
    </header>
  )
}
